import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'
import { MapForm } from '../forms/MapForm'
import './index.css'


const MapPageTemplate = ({ title, subtitle, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <section id="toppart" className='hero is-primary is-bold is-medium'>
          <div className='hero-body'>
            <div className='container'>
              <div className='columns'>
                <div className='column is-10 is-offset-1'>
                  <div className='section'>
                  <h1 className='title'>
                      {title}
                    </h1>
                    <h2 className='subtitle'>
                      {subtitle}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='section3'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <iframe id="map2" src='https://www.google.com/maps/d/u/1/embed?mid=1cjqSpE7YaP2RJFPCOVxQuTl4QWf6VTy9' scrolling="no"></iframe>
                  <h2><strong>Donor Map Form</strong></h2>
                  <br></br>
                  <p id="contactdesc"> Please note: all submissions are subject to moderation and verification. We do not report any personally identifiable information on the map.</p>
                  <br></br>
                  <MapForm></MapForm>
                  <br></br>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

MapPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default MapPageTemplate
